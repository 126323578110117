import React, { FC } from "react";
import styles from "./viewButton.module.scss";
import { IViewButton } from "./viewButton.interface";
import { view_icon } from "@jat-med/assets";

export const ViewButton: FC<IViewButton> = ({ onClick }) => {
  return (
    <button className={styles.view_button} onClick={onClick}>
      <img src={view_icon} alt="" />
    </button>
  );
};
