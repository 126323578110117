// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../node_modules/react-big-calendar/lib/css/react-big-calendar.css";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/fonts/Ubuntu-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/fonts/Ubuntu-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./assets/fonts/Ubuntu-Bold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Ubuntu";
  src: local("Ubuntu-Light"),
    url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Ubuntu";
  src: local("Ubuntu-Regular"),
    url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Ubuntu";
  src: local("Ubuntu-Bold"),
    url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype");
  font-weight: 700;
  font-style: normal;
}

* {
  margin: 0;
  font-family: "Ubuntu", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

#root {
  background-color: #f5f7fa;
  --header-height: 120px;

  --overlay-color: rgba(0 0 0 / 60%);

  --header-z-index: 50;

  @media (max-width: 1199px) {
    --header-height: 120px;
  }

  @media (max-width: 991px) {
    --header-height: 90px;
  }

  @media (max-width: 767px) {
    --header-height: 50px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;EACE,qBAAqB;EACrB;8DAC2D;EAC3D,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,qBAAqB;EACrB;8DAC6D;EAC7D,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,qBAAqB;EACrB;8DAC0D;EAC1D,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,SAAS;EACT,iCAAiC;EACjC,mCAAmC;EACnC,kCAAkC;EAClC,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;EACzB,sBAAsB;;EAEtB,kCAAkC;;EAElC,oBAAoB;;EAEpB;IACE,sBAAsB;EACxB;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE,qBAAqB;EACvB;AACF;;AAEA;EACE;aACW;AACb","sourcesContent":["@import \"react-big-calendar/lib/css/react-big-calendar.css\";\n\n@font-face {\n  font-family: \"Ubuntu\";\n  src: local(\"Ubuntu-Light\"),\n    url(\"./assets/fonts/Ubuntu-Light.ttf\") format(\"truetype\");\n  font-weight: 300;\n  font-style: normal;\n}\n@font-face {\n  font-family: \"Ubuntu\";\n  src: local(\"Ubuntu-Regular\"),\n    url(\"./assets/fonts/Ubuntu-Regular.ttf\") format(\"truetype\");\n  font-weight: 400;\n  font-style: normal;\n}\n@font-face {\n  font-family: \"Ubuntu\";\n  src: local(\"Ubuntu-Bold\"),\n    url(\"./assets/fonts/Ubuntu-Bold.ttf\") format(\"truetype\");\n  font-weight: 700;\n  font-style: normal;\n}\n\n* {\n  margin: 0;\n  font-family: \"Ubuntu\", sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  box-sizing: border-box;\n}\n\n#root {\n  background-color: #f5f7fa;\n  --header-height: 120px;\n\n  --overlay-color: rgba(0 0 0 / 60%);\n\n  --header-z-index: 50;\n\n  @media (max-width: 1199px) {\n    --header-height: 120px;\n  }\n\n  @media (max-width: 991px) {\n    --header-height: 90px;\n  }\n\n  @media (max-width: 767px) {\n    --header-height: 50px;\n  }\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
