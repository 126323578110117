import React from "react";
import i18n from "i18next";
import { Button } from "../Buttons/Button";
import styles from "./languagMenu.module.scss";
import { changeLocale } from "../../shared/i18n";

export const LanguageMenu = () => {
  return (
    <div className={styles.language_menu}>
      <Button
        onClick={() => changeLocale("pl")}
        label="PL"
        className="round_button"
        customStyle={i18n.resolvedLanguage === "pl" && styles.active}
      />
      <Button
        onClick={() => changeLocale("uk")}
        label="UA"
        className="round_button"
        customStyle={i18n.resolvedLanguage === "uk" && styles.active}
      />
      <Button
        onClick={() => changeLocale("en")}
        label="EN"
        className="round_button"
        customStyle={i18n.resolvedLanguage === "en" && styles.active}
      />
    </div>
  );
};
