import React, { FC } from "react";
import styles from "./adminPanelHeader.module.scss";
import { LanguageMenu } from "../../LanguageMenu";
import { IAdminPanelHeader } from "./adminPanelHeader.interface";
import { hamburger } from "@jat-med/assets";

export const AdminPanelHeader: FC<IAdminPanelHeader> = ({
  setIsSidebarClose,
  isSidebarClose,
}) => {
  return (
    <div className={styles.admin_header_wrap}>
      <div className={styles.left_part}>
        <div
          className={`${styles.icon_menu} ${
            isSidebarClose && styles.rotate_icon
          }`}
          onClick={() => setIsSidebarClose(!isSidebarClose)}
        >
          <img src={hamburger} alt="menu_icon" />
        </div>
      </div>
      <div className={styles.right_part}>
        <LanguageMenu />
      </div>
    </div>
  );
};
