import React, { FC } from "react";
import styles from "./tableDeleteButton.module.scss";
import { delete_icon } from "@jat-med/assets";
import { ITableDeleteButtonProps } from "./tableDeleteButton.interface";

export const TableDeleteButton: FC<ITableDeleteButtonProps> = ({ onClick }) => {
  return (
    <button className={styles.table_delete_button} onClick={onClick}>
      <img src={delete_icon} alt="" />
    </button>
  );
};
