import React, { FC, useEffect, useState } from "react";
import { arrow_acc, logo } from "../../../assets";
import { Button } from "../Buttons/Button";
import { NavMenu } from "../NavMenu";
import { LanguageMenu } from "../LanguageMenu";
import { useTranslation } from "react-i18next";
import { CustomLink } from "../CustomLink";
import { ModalContentContainer } from "../ModalContentContainer";
import { UserDataForm } from "../UserDataForm";
import {
  Faqs,
  IContactUsDto,
  ILoginDto,
  IRegisterDto,
  IUser,
  RoutesEnum,
  orserCallForm,
  userForm,
  userFormSignIn,
} from "../../shared";
import { UserBadge } from "../UserBadge";
import { FormApi } from "final-form";
import { useNavigate } from "react-router-dom";
import { Modal } from "../Modal";
import { contactUsApi } from "../../store/services";
import { showError } from "../../store/reducers";
import { useAppDispatch } from "../../hooks";
import styles from "./header.module.scss";

export interface IHeader {
  fetchedCurrentUser?: IUser;
  showLoginModal: boolean;
  showQuestionModal: boolean;
  handleOpenModal: (val: string) => void;
  handleCloseModal: () => void;
  handleLoginRequest: (arg: ILoginDto) => void;
  handleLogoutRequest: () => void;
  handleRegisterRequest: (
    arg: IRegisterDto,
    form: FormApi<IRegisterDto>
  ) => void;
}

export const Header: FC<IHeader> = ({
  fetchedCurrentUser,
  showLoginModal,
  showQuestionModal,
  handleOpenModal,
  handleCloseModal,
  handleRegisterRequest,
  handleLoginRequest,
  handleLogoutRequest,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showContactUsModal, setShowContactUsModal] = useState(false);

  const [handleContactUs] = contactUsApi.useSendMessageMutation();

  const onHandleContactUs = async (
    values: IContactUsDto,
    form: FormApi<IContactUsDto>
  ) => {
    try {
      await handleContactUs(values).unwrap();
      setShowContactUsModal(true);
      handleCloseModal();
      form.restart();
    } catch (error: any) {
      dispatch(
        showError(
          t([`alert.errors.${error.data.message}`, `alert.somethingWentWrong`])
        )
      );
    }
  };

  const handleHideModal = () => {
    setShowContactUsModal(false);
  };

  useEffect(() => {
    if (showQuestionModal || showLoginModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showQuestionModal, showLoginModal]);

  return (
    <div
      className={`${styles.header_container} ${
        isMenuOpen && styles.header_container_menu_open
      }`}
    >
      <CustomLink to="/" customStyle={styles.logo_link}>
        <img src={logo} alt="Logo" />
      </CustomLink>
      <div className={styles.main_section}>
        <div className={styles.top_part}>
          <div
            className={`${styles.burger}`}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <label
              className={`${styles.menu_btn} ${
                isMenuOpen && styles.menu_open
              } `}
            >
              <span></span>
            </label>
          </div>
          {!fetchedCurrentUser && (
            <Button
              onClick={() => handleOpenModal("login")}
              label={t("buttons.loginAndRegister")}
              className="small_light_green"
            />
          )}
          <Button
            onClick={() => handleOpenModal("question")}
            label={t("buttons.orderCall")}
            className="small_green"
          />
          {fetchedCurrentUser && (
            <UserBadge handleLogoutRequest={handleLogoutRequest} />
          )}
        </div>
        <div
          className={`${styles.bottom_part} ${isMenuOpen && styles.menu_open}`}
        >
          <NavMenu />
          <div className={styles.action_menu}>
            <LanguageMenu />{" "}
          </div>
        </div>
      </div>

      <Modal isOpen={showLoginModal} onClose={handleCloseModal}>
        <ModalContentContainer
          text={t("entriesForms.oldUser")}
          modalTitle={t("entriesForms.login")}
        >
          <UserDataForm
            onSubmit={(arg) => handleLoginRequest(arg as ILoginDto)}
            formData={userForm}
            buttonLabel={t("buttons.login")}
          />
        </ModalContentContainer>
        <ModalContentContainer
          text={t("entriesForms.newUser")}
          modalTitle={t("entriesForms.register")}
        >
          <UserDataForm
            onSubmit={(arg, form) =>
              handleRegisterRequest(arg as IRegisterDto, form)
            }
            formData={userFormSignIn}
            buttonLabel={t("buttons.register")}
          />
        </ModalContentContainer>
      </Modal>
      <Modal isOpen={showQuestionModal} onClose={handleCloseModal}>
        <ModalContentContainer
          text={t("entriesForms.wishCall")}
          modalTitle={t("entriesForms.orderCall")}
          customStyles={styles.order_call_modal}
        >
          <UserDataForm
            onSubmit={(arg, form) =>
              onHandleContactUs(arg as IContactUsDto, form)
            }
            formData={orserCallForm}
            buttonLabel={t("buttons.send")}
          />
        </ModalContentContainer>
        <ModalContentContainer
          text={t("maybeAnswered")}
          modalTitle={t("navigation.faq")}
          customStyles={styles.order_call_modal}
        >
          {Faqs.slice(0, 6).map((item, index) => (
            <div
              key={index}
              className={styles.content_wrap}
              onClick={() => {
                handleCloseModal();
                navigate(`${RoutesEnum.faq}?faq_id=${index}`);
              }}
            >
              <p>{t(item.question)}</p>
              <div className={styles.content}>
                <img src={arrow_acc} alt="arrow" />
              </div>
            </div>
          ))}
          <Button
            onClick={() => {
              handleCloseModal();
              navigate(RoutesEnum.faq);
            }}
            label={t("buttons.viewMore")}
            className="small_light_green"
            customStyle={styles.view_more_button}
          />
        </ModalContentContainer>
      </Modal>
      <Modal
        isOpen={showContactUsModal}
        onClose={handleHideModal}
        customStyles={styles.ContactUsModal}
      >
        <ModalContentContainer
          text=""
          modalTitle={t("messageSent")}
          customStyles={styles.ContactUsModalContent}
        >
          <p className={styles.modalText}>{t("youWillBeContacted")}</p>
        </ModalContentContainer>
      </Modal>
    </div>
  );
};
